import Vue from "vue";
import VueRouter from "vue-router";
import VueGtm from "@gtm-support/vue2-gtm";
import VueMeta from "vue-meta";
import store from "../store";

Vue.use(VueRouter);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

const HomePage = () =>
  import(/* webpackChunkName: "homePage" */ "@/views/HomePage.vue");

const SupportPage = () =>
  import(/* webpackChunkName: "supportPage" */ "@/views/SupportPage.vue");

const BlogPage = () =>
  import(/* webpackChunkName: "blogPage" */ "@/views/BlogPage.vue");

const DetailedBlogPage = () =>
  import(/* webpackChunkName: "detailedBlogPage" */ "@/views/DetailedBlogPage.vue");

const CreateAccountPage = () =>
  import(
    /* webpackChunkName: "create-account" */ "@/views/CreateAccountPage.vue"
  );

const ProductPage = () =>
  import(/* webpackChunkName: "ProductPage" */ "@/views/ProductPage.vue");

const FAQ = () =>
  import(/* webpackChunkName: "faq" */ "@/views/FAQ.vue");

const ProductContentPage = () =>
  import( "../views/ProductContent/ProductContentPage.vue")

const Error = () =>
  import(/* webpackChunkName: "error" */ "../views/Error.vue");

const CookiePolicy = () =>
  import(/* webpackChunkName: "cookie-policy" */ "../views/CookiePolicy.vue");

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage
  },
  {
    path: "/kontaktaoss",
    name: "SupportPage",
    component: SupportPage
  },
  {
    path: "/blogg",
    name: "BlogPage",
    component: BlogPage
  },
  {
    path: "/blogg/:urlSlug",
    name: "DetailedBlogPage",
    component: DetailedBlogPage
  },
  {
    path: "/fakturerings-bokforingsprogram",
    name: "ProductPage",
    component: ProductPage
  },
  {
    path: "/fakturerings-bokforingsprogram/:id",
    name: "ProductContentPage",
    component: ProductContentPage
  },
  {
    path: "/fragor-svar",
    name: "FAQ",
    component: FAQ
  },
  {
    path: "/eurofinans",
    name: "HomeEuro",
    component: HomePage
  },
  {
    path: "/prioritetfinans",
    name: "HomePrioritet",
    component: HomePage
  },
  {
    path: "/cookie-policy",
    name: "CookiePolicy",
    component: CookiePolicy
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: Error
  },
  {
    path: "/support",
    redirect: to => {
      return { path: "/kontaktaoss" }
    }
  },
  {
    path: "/bokforingsprogram",
    redirect: to => {
      return { path: "/fakturerings-bokforingsprogram" }
    }
  },
  {
    path: "/cookiepolicy",
    redirect: to => {
      return { path: "/cookie-policy" }
    }
  },
  {
    path: "/ekonomiprogram",
    redirect: to => {
      return { path: "/fakturerings-bokforingsprogram" }
    }
  },

  {
    path: "*",
    component: Error
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth"
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

// Needed to navigate to the right "start page" when clicking on the nav-brand logo.
router.beforeEach((to, from, next) => {
  if (to.path === "/eurofinans") {
    store.commit("base/setVisitedEuroFinans");
  }

  if (to.path === "/prioritetfinans") {
    store.commit("base/setVisitedPrioritetFinans");
  }

  next();
});

// Remove cache and reload page with cache disabled
async function onErrorClearCacheAndReloadPage() {
  if (caches) {
    const names = await caches.keys();
    await Promise.all(names.map(name => caches.delete(name)));
  }
  window.location.assign(router.history.pending.fullPath);
}
router.onError(error => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    onErrorClearCacheAndReloadPage();
  }
});

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID, // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  defer: true, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: process.env.VUE_APP_GTM_DEBUG === "enabled", // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});

export default router;
