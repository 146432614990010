import blogApi from "../api/blogApi";

export default {
  namespaced: true,

  state: {
    blogPosts: [],
    blogCategories: [],
    blogPost: null,
    heroBlogPost: null,
    hasMorePosts: false,
  },

  getters: {
    blogPosts: state => {
      return state.blogPosts;
    },
    blogPost: state => {
      return state.blogPost;
    },
    heroBlogPost: state => {
      return state.heroBlogPost;
    },
    blogCategories: state => {
      return state.blogCategories;
    },
    hasMorePosts: state => {
      return state.hasMorePosts;
    },
  },

  mutations: {
    setBlogPosts(state, posts) {
      state.blogPosts = posts
    },
    appendBlogPosts(state, posts) {
      state.blogPosts = [...state.blogPosts, ...posts]
    },
    setBlogPost(state, post) {
      state.blogPost = post
    },
    setHeroBlogPost(state, post) {
      state.heroBlogPost = post
    },
    setBlogCategories(state, categories) {
      state.blogCategories = categories
    },
    setHasMorePosts(state, hasMore) {
      state.hasMorePosts = hasMore;
    },
  },
  actions: {
    getBlogPosts({state, commit}, filter) {
      blogApi.getBlogPosts(filter).then(posts => {
        if (filter.append) {
          commit('appendBlogPosts', posts)
        } else {
          commit('setBlogPosts', posts)
        }
        commit('setHasMorePosts', posts.length == 9)
      })
    },
    getBlogCategories({state, commit}) {
      blogApi.getBlogCategories().then(categories => {
        commit('setBlogCategories', categories)
      })
    },
    getBlogPost({state, commit}, urlSlug) {
      blogApi.getBlogPost(urlSlug).then(post => {
        commit('setBlogPost', post)
      })
    },
    getLatestBlogPost({state, commit}) {
      blogApi.getLatestBlogPost().then(post => {
        commit('setHeroBlogPost', post)
      })
    }
  }
};
