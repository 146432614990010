export default {
  async getLatestBlogPost() {
    try {
      const url = `${process.env.VUE_APP_BLOGPOSTS_API_URL}/latest`
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }

      const json = await response.json();

      return json
    } catch(error) {
      console.error(error.message);
    }
  },

  async getBlogPost(urlSlug, preview) {
    try {
      let url = `${process.env.VUE_APP_BLOGPOSTS_API_URL}/${urlSlug}`

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }

      const json = await response.json();

      return json
    } catch(error) {
      console.error(error.message);
    }
  },

  async getBlogPosts(filter) {
    try {
      const url = process.env.VUE_APP_BLOGPOSTS_API_URL

      const response = await fetch(`${url}/?${new URLSearchParams(filter).toString()}`);
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }

      const json = await response.json();

      return json
    } catch(error) {
      console.error(error.message);
    }
  },

  async getBlogCategories() {
    try {
      const url = process.env.VUE_APP_BLOGCATEGORIES_API_URL
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }

      const json = await response.json();


      return json
    } catch(error) {
      console.error(error.message);
    }
  }
}
